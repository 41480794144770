import React from "react"
import { graphql } from "gatsby"
import Cases from "../components/cases/"
import Hero from "../components/hero/"
import { Title, Preamble, Text } from "../components/cases/desktop/Style"

const Case = ({ data }) => {
  const { videoUrl, excerpt, title, content, id, image } = data.contentfulCases
  return (
    <>
      <Hero video={videoUrl ? videoUrl : null} image={videoUrl ? null : image.fluid.src }>
        <Title>{title}</Title>
        {excerpt && <Preamble>{excerpt.excerpt}</Preamble>}
        {content && <Text dangerouslySetInnerHTML={{ __html: content.content }}></Text>}
      </Hero>
      <Cases title="Mer case" exclude={id} />
    </>
  )
}

export const query = graphql`
  query($slug: String!) {
    contentfulCases(slug: { eq: $slug }) {
      id
      title
      excerpt {
        excerpt
      }
      content {
        content
      }
      videoUrl
      image {
        fluid {
          ...GatsbyContentfulFluid_withWebp
        }
      }
    }
  }
`

export default Case
