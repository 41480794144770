import styled from "styled-components"
import { Preamble } from "../Text"
import { sidePadding, colors, breakpoints } from "../Style"

export const Title = styled.h1`
  margin-bottom: 1.3rem;
  font-size: 2.4rem;
  color: ${colors.yellow};
  font-weight: 700;
  line-height: 1.05;

  @media ${breakpoints.medium} {
    margin-bottom: 2.3rem;
    font-size: 4.8rem;
    line-height: 5.4rem;
  }
`

export const Description = styled(Preamble)`
  margin-bottom: 2.5rem;
`

export const List = styled.ul`
  margin-bottom: 5.3rem;
`

export const Actions = styled.div`
  display: flex;
  flex-flow: row nowrap;
  margin: auto -0.1rem ${sidePadding}rem -0.1rem;

  & > * {
    flex: 1;
    margin: 0 0.1rem;
  }
`
