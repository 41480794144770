import styled from "styled-components"
import { Preamble as Pre } from "../../Text"
import { Title as H1 } from "../../post/Style"

export const Title = styled(H1)``

export const Preamble = styled(Pre)`
  margin-bottom: 2.6rem;
`

export const Text = styled.p`
  margin-bottom: 5.2rem;
  font-size: 2rem;
  line-height: 1.3;
  a {
    color: #ffc72c;
  }
`

export const Action = styled.div`
  margin-top: auto;
  text-align: right;
`
