import styled from "styled-components"
import Flickity from "react-flickity-component"
import { colors, easings, breakpoints, sidePadding } from "../Style"

export const Cell = styled.div`
  width: 100%;
`

export const StyledFlickity = styled(Flickity)`
  &:focus,
  &.flickity-enabled:focus {
    outline: none;
  }

  &.flickity-enabled {
    position: relative;
  }

  .flickity-viewport {
    overflow: hidden;
    position: relative;
    height: 100%;
    transition: height 0.2s;
  }

  .flickity-slider {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  /* draggable */
  &.flickity-enabled.is-draggable {
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: transparent;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.flickity-enabled.is-draggable .flickity-viewport {
    cursor: move;
    cursor: -webkit-grab;
    cursor: grab;
  }

  &.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }

  .flickity-page-dots {
    width: 100%;
    padding: 0;
    margin: ${sidePadding}rem 0 0;
    list-style: none;
    text-align: center;
    line-height: 1;

    @media ${breakpoints.medium} {
      margin: 0;
    }

    @media ${breakpoints.large} {
      position: absolute;
      bottom: 1.7rem;
    }
  }

  .flickity-rtl .flickity-page-dots {
    direction: rtl;
  }

  .flickity-page-dots .dot {
    display: inline-block;
    width: 2.4rem;
    height: 2.4rem;
    margin: 0 0.8rem;
    background: ${colors.white};
    border-radius: 50%;
    cursor: pointer;
    transition: background-color 0.1s ${easings.appleOut};

    @media ${breakpoints.medium} {
      width: 2.5rem;
      height: 2.5rem;
    }
  }

  .flickity-page-dots .dot.is-selected {
    background: ${colors.yellow};
  }
`

export default StyledFlickity
