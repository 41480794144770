import styled from "styled-components"
import { H3 } from "../Text"
import { breakpoints, sidePadding } from "../Style"

export const Section = styled.section`
  @media ${breakpoints.medium} {
    margin-bottom: 7.5rem;
  }
`

export const Title = styled(H3)`
  margin-bottom: 1.4rem;
  text-align: center;

  @media ${breakpoints.medium} {
    margin-bottom: 3rem;
  }
`

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  @media ${breakpoints.medium} {
    margin-bottom: 3.4rem;
  }

  & > * {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: ${sidePadding}rem;
    }

    @media ${breakpoints.medium} {
      max-width: 50%;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    @media ${breakpoints.large} {
      max-width: 33.33%;
    }
  }
`

export const Footer = styled.div`
  margin-bottom: 7.5rem;
  margin-top: 2.5rem;
  text-align: center;

  @media ${breakpoints.medium} {
    margin-top: 0;
  }
`
