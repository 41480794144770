import React from "react"
import StyledFlickity, { Cell } from "./Style"

const flickityOptions = {
  adaptiveHeight: true,
  prevNextButtons: false,
}

export { Cell }

export default function Carousel({ options, children }) {
  return <StyledFlickity options={options || flickityOptions}>{children}</StyledFlickity>
}
