import React from "react"
import { Link } from "gatsby"
import useCases from "../../../lib/useCases"
import Carousel, { Cell } from "../../carousel"
import Button from "../../button"
import Hero from "../../hero/"
import { Title, Preamble, Text, Action } from "./Style"
import { Title as Headline } from "../../posts/Style"

export default function Cases({ title = false, exclude = false }) {
  const [cases] = useCases()

  const casesToDisplay = exclude
    ? cases.filter(c => c.node.id !== exclude)
    : cases

  return (
    casesToDisplay.length > 0 && (
      <>
        {title && <Headline as="h2">{title}</Headline>}
        <Carousel>
          {casesToDisplay.map(({ node }) => (
            <Cell key={node.slug}>
              <Hero video={node.videoUrl ? node.videoUrl : null} image={node.videoUrl ? null : node.image.fluid.src }>
                <Title>{node.title}</Title>
                {node.excerpt && <Preamble>{node.excerpt.excerpt}</Preamble>}
                {node.content && <Text dangerouslySetInnerHTML={{ __html: node.content.content }}></Text>}
                {node.slug && (
                  <Action>
                    <Button as={Link} to={`/${node.slug}`}>
                      Läs vidare
                    </Button>
                  </Action>
                )}
              </Hero>
            </Cell>
          ))}
        </Carousel>
      </>
    )
  )
}
