import styled from "styled-components"
import Placeholder from "../Placeholder"
import Icon from "../Icon"
import { colors, sidePadding, cover, breakpoints } from "../Style"

export const Container = styled.section`
  position: relative;
  margin: -${sidePadding}rem -${sidePadding}rem ${sidePadding}rem;

  @media ${breakpoints.medium} {
    margin: 0 0 ${sidePadding * 2}rem;
  }

  @media ${breakpoints.large} {
    display: flex;
    justify-content: flex-end;
    min-height: 44.8vw;
    margin-bottom: 9rem;
  }
`

export const MediaContainer = styled.div`
  position: relative;
  overflow: hidden;
  background-color: ${colors.black};

  ${Container} & {
    height: ${props => (props.video ? "56.25vw" : "44.8vw")};

    @media ${breakpoints.large} {
      position: absolute;
      top: 0;
      left: 0;
      width: 67vw;
      height: ${props => (props.video ? "37.65vw" : "44.8vw")};
    }
  }
`

export const Media = styled.img`
  ${cover()};
`

export const Content = styled.div`
  position: relative;
  padding: 2.3rem ${sidePadding}rem;
  background-color: ${colors.gray["700"]};

  @media ${breakpoints.small} {
    padding: 3.2rem;
  }

  @media ${breakpoints.medium} {
    margin: -${sidePadding * 2}rem ${sidePadding * 2}rem 0;
  }

  @media ${breakpoints.large} {
    padding: 4.3rem;
    margin: 0;

    ${Container} & {
      position: relative;
      top: ${sidePadding * 2}rem;
      display: flex;
      flex-flow: column nowrap;
      width: 33vw;
      min-height: 100%;
    }
  }
`

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 7.2rem;
  height: 7.2rem;
  transform: translate(-50%, -50%);
  color: ${colors.yellow};
`

export const StyledPlaceholder = styled(Placeholder)`
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 15rem;
  transform: translate(-50%, -50%);
`
