import React from "react"
import {
  Container,
  MediaContainer,
  Media,
  Content,
  StyledPlaceholder,
} from "./Style"
import { Youtube } from "../Video"

export { Container, MediaContainer, Content, Media }

export default function Hero({ children, video = false, image = false }) {
  return (
    <Container>
      <MediaContainer video={video}>
        <StyledPlaceholder />
        {video && <Youtube src={video} />}
        {image && <Media src={image} />}
      </MediaContainer>
      <Content>{children}</Content>
    </Container>
  )
}
