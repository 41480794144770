import styled from "styled-components"
import Icon from "../../Icon"
import { Preamble as Pre, Text } from "../../Text"
import { colors, cover, resetLink } from "../../Style"
import { hexToRgb } from "../../../utils/"

export const Container = styled.article`
  ${resetLink}
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  color: ${colors.black};
  background-color: ${colors.white};
  text-decoration: none;
`

export const Background = styled.img`
  ${cover()}
`

export const Foreground = styled.div`
  position: relative;
  z-index: 1;
`

export const Spacer = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 64%;
`

export const StyledIcon = styled(Icon)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 7.2rem;
  height: 7.2rem;
  transform: translate(-50%, -50%);
  color: ${colors.yellow};
`

export const Content = styled.div`
  padding: 1.9rem 2rem 2.2rem;
  background-color: rgba(${hexToRgb(colors.gray["100"]).rgb}, 0.8);
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.05);
`

export const Category = styled.p`
  margin-bottom: 0.6rem;
  color: ${colors.gray["500"]};
  font-size: 1.2rem;
  letter-spacing: 0.5rem;
  text-transform: uppercase;
`

export const Title = styled.h1`
  margin-bottom: 0.6rem;
  color: ${colors.complementary.blue};
  font-size: 2.4rem;
  line-height: 1.25;
  font-weight: 700;
`

export const Preamble = styled(Pre)`
  margin-bottom: 1.2rem;
  line-height: 1.2;
  font-weight: 400;
`

export const Description = styled(Text)`
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 1.9rem;
`

export const Action = styled.div`
  text-align: right;
`
