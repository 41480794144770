import React from "react"
import styled from "styled-components"
import Epiroc from "../lib/useLogo"
import { ImgContainer } from "./card/Style"

const Img = styled(Epiroc)`
  ${ImgContainer} & {
    top: 50%;
    left: 50%;
    object-fit: none;
    transform: translate(-50%, -50%);
  }
}
`

export default function Placeholder({ ...props }) {
  return <Img role="presentation" {...props} />
}
