import { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

export default function useCases() {
  const [cases, setCases] = useState([])

  const data = useStaticQuery(graphql`
    query casesQuery {
      allContentfulCases(limit: 6, sort: { fields: [createdAt], order: DESC }) {
        edges {
          node {
            id
            slug
            title
            excerpt {
              excerpt
            }
            content {
              content
            }
            videoUrl
            image {
              fluid {
                ...GatsbyContentfulFluid_withWebp
              }
            }
          }
        }
      }
    }
  `)

  useEffect(() => {
    setCases(data.allContentfulCases.edges)
  }, [data.allContentfulCases.edges])

  return [cases, setCases]
}
