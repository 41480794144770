import React from "react"
import useCases from "../../../lib/useCases"
import Carousel, { Cell } from "../../carousel"
import CaseCard from "../../case/card"
import { Section } from "./Style"
import { Title as Headline } from "../../posts/Style"

export default function Cases({ title = false }) {
  const [cases] = useCases()

  return (
    cases.length > 0 && (
      <Section>
        {title && <Headline as="h2">{title}</Headline>}
        <Carousel>
          {cases.map(({ node }) => (
            <Cell key={node.slug}>
              <CaseCard
                to={node.slug}
                title={node.title}
                preamble={node.excerpt.excerpt}
                videoUrl={node.videoUrl}
                image={node.image}
              />
            </Cell>
          ))}
        </Carousel>
      </Section>
    )
  )
}
