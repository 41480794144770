import React from "react"
import useMobile from "../../lib/useMobile"
import CasesMobile from "./mobile"
import CasesDesktop from "./desktop"

export default function Cases({ ...props }) {
  const [isMobile] = useMobile()

  return isMobile ? <CasesMobile {...props} /> : <CasesDesktop {...props} />
}
