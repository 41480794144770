import styled from "styled-components"
import { Text } from "../Text"
import { colors, breakpoints, easings, resetLink, cover } from "../Style"
import { hexToRgb } from "../../utils"
import { Link } from "gatsby"

export const StyledCard = styled(Link)`
  ${resetLink};
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    246.76999999999998deg,
    ${colors.gray["700"]} 0%,
    #353432 100%
  );
  background-color: ${colors.gray["700"]};
  box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, 0.05), inset 0 0 0 transparent;
  transition-property: box-shadow, background-color;
  transition-duration: 0.2s;
  transition-timing-function: ${easings.appleOut};

  &:hover,
  &:focus {
    background: #464543;
    box-shadow: 0 0.1rem 0.6rem 0 rgba(0, 0, 0, 0.05),
      inset 0 -0.4rem 0 ${colors.yellow};
  }
`

export const ImgContainer = styled.div`
  position: relative;
  height: 0;
  padding-bottom: 63.63%;
  background-color: rgba(${hexToRgb(colors.dark).rgb}, 0.2);
  overflow: hidden;

  @media ${breakpoints.medium} {
    padding-bottom: 76.17%;
  }

  img {
    ${cover()}
    filter: grayscale(100%);
    transition: filter 0.2s ${easings.appleOut};

    ${StyledCard}:hover & {
      filter: grayscale(0);
    }
  }
`

export const Content = styled.div`
  padding: 2rem;

  @media ${breakpoints.medium} {
    padding: 3.4rem 3.2rem 2rem;
  }
`

export const Header = styled.header`
  display: flex;
  flex-flow: row nowrap;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 1.3rem;

  @media ${breakpoints.medium} {
    margin-bottom: 2.6rem;
  }
`

export const Title = styled.h3`
  color: ${colors.yellow};
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.05;

  @media ${breakpoints.medium} {
    font-size: 3rem;
  }
`

export const Year = styled.span`
  display: block;
  color: #dddddd;
  font-weight: 400;

  @media ${breakpoints.medium} {
    display: inline;
  }
`

export const Brand = styled.span``

export const Model = styled.span`
  font-weight: 400;
  @media ${breakpoints.medium} {
    &:not(:last-child) {
      margin-right: 1rem;
    }
  }
`

export const Description = styled(Text)`
  margin-bottom: 1rem;
  font-weight: 400;

  @media ${breakpoints.medium} {
    margin-bottom: 1.4rem;
  }
`
export const Footer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-content: space-between;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;

  @media ${breakpoints.medium} {
    margin-top: 3.5rem;
  }
`
