import React from "react"
import { Link as GatsbyLink } from "gatsby"
import Img from "gatsby-image"
import {
  Container,
  Foreground,
  Spacer,
  StyledIcon,
  Content,
  Category,
  Title,
  Description,
  Action,
  Preamble,
  Background,
} from "./Style"
import { NoStateLink as Link } from "../../Link"
import { getVideoID } from "../../../utils/"

export function CaseCardContent({
  category = "case",
  title,
  preamble,
  description,
  to,
}) {
  return (
    <Content>
      <footer>
        <Category>{category}</Category>
      </footer>
      <Title>{title}</Title>
      {preamble && <Preamble>{preamble}</Preamble>}
      {description && <Description>{description}</Description>}
      {to && (
        <Action>
          <Link as="span" icon={true} dark={true}>
            Läs vidare
          </Link>
        </Action>
      )}
    </Content>
  )
}

export default function CaseCard({
  to,
  videoUrl,
  title,
  preamble,
  description,
  image,
}) {
  const imageAttributes = image
    ? {
        as: Img,
        fluid: image.fluid,
        style: { position: "absolute" },
      }
    : {
        src: `https://img.youtube.com/vi/${getVideoID(videoUrl)}/0.jpg`,
      }

  return (
    <Container as={to ? GatsbyLink : null} to={to ? `/${to}` : null}>
      <Background {...imageAttributes} />
      <Foreground>
        <Spacer>
          {videoUrl && (
            <StyledIcon icon={["fal", "play-circle"]} />
          )}
        </Spacer>
        <CaseCardContent
          title={title}
          description={description}
          preamble={preamble}
          to={to}
        />
      </Foreground>
    </Container>
  )
}
